<!--
 * @Author: lzh
 * @Date: 2022-07-16 16:32:02
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-12 17:28:28
 * @Description: file content
-->
<template>
  <div class="edit-tabs-box">
    <div class="cell-b">
      <h4>主標題</h4>
      <el-input
        v-for="lItem in language"
        :key="lItem.code"
        v-model="configs.items[index][`${lItem.code=='HK'?'':lItem.code}title`]"
        :placeholder="`請輸入-${lItem.name}`"
        @input="setValue('items')"
      ></el-input>
    </div>
    <div v-if="index == 0">
      <div class="cell-b">
        <div class="cell-b">
          <h3>項-標題配置</h3>
          <el-input
            v-for="lItem in language"
            :key="lItem.code"
            v-model="configs.items[0].configs[`${lItem.code=='HK'?'':lItem.code}title`]"
            :placeholder="`請輸入-${lItem.name}`"
            @input="setValue('items')"
          ></el-input>
        </div>
        <div class="cell-b">
          <h3>項-描述配置</h3>
          <el-input
            v-for="lItem in language"
            :key="lItem.code"
            v-model="configs.items[0].configs[`${lItem.code=='HK'?'':lItem.code}desc`]"
            :placeholder="`請輸入-${lItem.name}`"
            @input="setValue('items')"
          ></el-input>
        </div>
        <div class="cell-b">
          <h3>項-地址配置</h3>
          <div class="cell-b">
            <el-input
              v-for="lItem in language"
              :key="lItem.code"
              v-model="configs.items[0].configs[`${lItem.code=='HK'?'':lItem.code}address`]"
              :placeholder="`請輸入-${lItem.name}`"
              @input="setValue('items')"
              type="textarea"
              rows="3"
            ></el-input>
          </div>
          <div class="cell-b">

            <el-input
              v-for="lItem in language"
              :key="lItem.code"
              style="margin-top: 10px"
              v-model="configs.items[0].configs[`${lItem.code=='HK'?'':lItem.code}addressDetail`]"
              :placeholder="`請輸入-${lItem.name}`"
              @input="setValue('items')"
              type="textarea"
              rows="3"
            ></el-input>
          </div>
        </div>
        <h3>選擇圖片</h3>
        <selfUpload
          v-model="configs.items[0].configs.img"
          type="default"
          @change="setValue('items')"
        />
        <selfCell title="輸入圖片描述">
          <el-input
            v-model="configs.items[0].configs.alt"
            type="textarea"
            @change="setValue('items')"
          ></el-input>
        </selfCell>
        <h3>選擇logo</h3>
        <selfUpload
          v-model="configs.items[0].configs.logo"
          type="default"
          @change="setValue('logo')"
        />
        <div class="cell-b">
          <h3>項-描述配置</h3>
          <el-input
            v-for="lItem in language"
            :key="lItem.code"
            v-model="configs.items[0].configs[`${lItem.code=='HK'?'':lItem.code}logoDesc`]"
            :placeholder="`請輸入-${lItem.name}`"
            type="textarea"
            rows="3"
            @input="setValue('items')"
          ></el-input>
        </div>
        <selfCell
          style="margin-top: 10px"
          title="請選擇圖片"
        >
          <selfUpload
            v-model="configs.items[0].configs.manageImage"
            type="default"
            @change="setValue('items')"
          />
        </selfCell>

        <selfCell title="輸入圖片描述">
          <el-input
            v-model="configs.items[0].configs.manageImageAlt"
            type="textarea"
            @change="setValue('items')"
          ></el-input>
        </selfCell>
        <div class="cell-b">
          <h3>項-標題配置</h3>
          <el-input
            v-for="lItem in language"
            :key="lItem.code"
            v-model="configs.items[0].configs[`${lItem.code=='HK'?'':lItem.code}manageTitle`]"
            :placeholder="`請輸入-${lItem.name}`"
            @input="setValue('items')"
          ></el-input>
        </div>
        <div class="cell-b">
          <h3>項-描述配置</h3>
          <el-input
            v-for="lItem in language"
            :key="lItem.code"
            v-model="configs.items[0].configs[`${lItem.code=='HK'?'':lItem.code}manageDesc`]"
            :placeholder="`請輸入-${lItem.name}`"
            rows="3"
            type="textarea"
            @input="setValue('items')"
          ></el-input>
        </div>
      </div>
    </div>
    <div v-else-if="index == 1">

      <el-button
        v-if="configs.items[1].configs.items.length == 0"
        class="y-add"
        @click="yAdd(0)"
      >新增</el-button>
      <div
        class="y-box"
        v-for="(item, index) in configs.items[1].configs.items"
        :key="index"
      >
        <h4>時間</h4>
        <div class="y-cell">
          <div>
            <el-input
              v-for="lItem in language"
              :key="lItem.code"
              v-model="item[`${lItem.code=='HK'?'':lItem.code}yyyy`]"
              @input="setValue('items')"
              :placeholder="`請輸入-${lItem.name}`"
            />
          </div>
          <el-tooltip
            class="item"
            effect="dark"
            content="向上插入"
            placement="bottom"
          >
            <el-button
              icon="el-icon-top"
              size="mini"
              circle
              @click="yAdd(index)"
            ></el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="向下插入"
            placement="bottom"
          >
            <el-button
              icon="el-icon-bottom"
              size="mini"
              circle
              @click="yAdd(index+1)"
            ></el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="刪除"
            placement="bottom"
          >
            <el-button
              icon="el-icon-delete"
              size="mini"
              circle
              @click="yDel(index)"
            ></el-button>
          </el-tooltip>
        </div>
        <!-- <div
          class="y-cell"
          style="margin-top:10px"
        >
          <el-input
            v-model="item.desc"
            type="textarea"
            :rows="2"
            placeholder="請輸入描述"
          >
          </el-input>
        </div> -->
        <h4>歷程</h4>
        <div
          class="y-cell"
          style="overflow:auto;"
        >
          <div
            style="width:300px;min-width:300px;"
            v-for="lItem in language"
            :key="lItem.code"
          >
            <editor
              api-key="no-api-key"
              :init="init"
              v-model="item[`${lItem.code=='HK'?'':lItem.code}desc`]"
              style="width:100%;height:200px;"
              @input="setValue('items')"
              :placeholder="`請輸入-${lItem.name}`"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="index == 2">
      <div class="cell-b">
        <editImage
          :configs="configs.items[2].configs"
          @setValue="setValue2"
        />
      </div>
    </div>
  </div>
</template>

<script>
import langEditMixin from '../../mixins/langEdit'
import editImage from "./image.vue"
import Editor from '@tinymce/tinymce-vue'
export default {
  mixins: [langEditMixin],
  name: 'edit-tabs',
  components: {
    editImage,
    'editor': Editor
  },
  props: {
    // tabs
    index: {
      default () {
        return '';
      },
    },
    configs: {
      default () {
        return {
          src: '',
          go: '',
        };
      },
    },
    activeTabIndex: {
      default () {
        return '';
      },
    },
    Xindex: {
      default () {
        return '';
      },
    },
  },
  data () {
    return {
      init: {
        height: 500,
        menubar: false,
        plugins: [
          'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
          'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
          'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount'
        ],
        toolbar:
          'undo redo | casechange blocks | bold italic backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlst checklist outdent indent | removeformat | a11ycheck code table help'
      }
    }
  },
  methods: {
    setValue2 (t) {
      this.configs.items[2].configs[t.key] = t.value;
      this.setValue('items')
    },
    setValue (key) {
      if (key == 'src') key = 'items'
      this.$emit('setValue', { key: key, value: this.configs[key] });
    },
    yAdd (index) {
      let items = this.configs.items[1].configs.items;
      items.splice(index, 0, {
        desc: "",
        yyyy: ""
      })
      this.setValue('items')
    },
    yDel (index) {
      let items = this.configs.items[1].configs.items;
      items.splice(index, 1);
      this.setValue('items')
    },
  },
};
</script>

<style lang="less" scoped>
.edit-tabs-box {
  .y-add {
    width: 100%;
  }
  .y-box {
    padding: 10px;
    box-shadow: 0 2px 6px #ccc;
    margin-bottom: 10px;
    border-radius: 4px;
    &:hover {
      box-shadow: 0 2px 6px var(--themeColor);
    }
    .y-cell {
      display: flex;
      align-items: center;
      & /deep/ .el-input {
        margin-right: 10px;
      }
    }
  }
}
</style>
